import React from "react";

const Component = () => (
  <div>
    <h1>Ueber uns</h1>
    <p>
      Die Verbindung von Tun und Erfahren, von Spielen und Hören, von Genießen
      und Gestalten führt zu einem tieferen Musikverständnis und einem höheren
      musikalischen Genuss. Unsere Ausbildung basiert auf der sorgfältigen
      Vermittlung einer umfassenden und modernen Technik des
      Streichinstrumentenspiels und der kontinuierlichen Entwicklung von
      künstlerischer und musikalischer Erlebnis- und Ausdrucksfähigkeit. Die
      gründliche instrumentale Ausbildung erfordert oft ein hohes Maß an Geduld.
      Eine stressfreie natürliche Entwicklung unserer Schüler hat daher bei uns
      höchste Priorität. „Der ist ein guter Lehrer, der sich nach und nach
      überflüssig macht.“ (George Orwell) Wir lehren das Wissen um das Tun.
      Dabei möchten wir dem Schüler die Fähigkeit vermitteln, sein eigener
      Lehrer zu werden und sich auf seinem Instrument selbständig
      weiterzuentwickeln. Musik ist kommunikativ. Unser musikalisches Podium
      schafft Freude und Selbstvertrauen.
    </p>
  </div>
);

export default Component;
